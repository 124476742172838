import React from "react"
import Layout from "../components/layout.component"
import { Navbar } from "../components/navbar.component"
import { RequestQuote } from "../components/request-quote.component"
import { ForSaleComponent } from "../components/for-sale.component"

const ForSale = ({}) => {
  const query = new URLSearchParams(typeof window !== "undefined" && window.location.search)
  const status = query.get("status");
  return (
    <Layout>
      <Navbar slides={[{}]}/>
      <div className="header header-light" style={{ backgroundColor: "white" }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1">
              <div className="section">
                {status === 'success' ? <div className="alert alert-success" role="alert">Request was sent successfully</div> : null}
                <h1>Cars For Sale</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <main className="main_alt">
        <ForSaleComponent />
      </main>
      <RequestQuote lang={'en-us'}/>
    </Layout>
  )
}

export default ForSale


