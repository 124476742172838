import React, { useState } from "react"
import axios from "axios"
import { Image } from "./common/image.component"
import { Link } from "gatsby"

const ports = {}
export const ForSaleComponent = ({}) => {
  const [cars, setCars] = useState([])
  const [total, setTotal] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [numPages, setNumPages] = useState(0)
  const query = new URLSearchParams(typeof window !== "undefined" && window.location.search)
  const skip = parseInt(query.get("skip"), 10) || 0
  const limit = parseInt(query.get("limit"), 10) || 21

  if (!cars || !cars.length) {
    axios.get(
      `https://api.usatrt.com/api/sale/?skip=${skip}&limit=${limit}`,
      {
        headers: {
          Accept: "application/json",
        },
      },
    ).then(result => {
      setCars(result.data.freights)
      setTotal(result.data.count)
      setNumPages(Math.floor(result.data.count / limit))
      setCurrentPage(Math.floor(skip / limit))
    });
  }
  return (
    <div className="section">
      <div className="container">
        <div className="row">
          {cars.length ? (
            <div className="col-sm-10 col-sm-offset-1 col-md-10 col-md-offset-1">
              <div className="row d-flex flex-wrap">
                {cars.map(({
                             AssemblyCountry,
                             BodyStyle,
                             Color,
                             Description,
                             EngineCyl,
                             EngineLiters,
                             EngineType,
                             Id,
                             LotNumber,
                             Make,
                             Model,
                             Pictures,
                             PortOfExport,
                             PortOfExportId,
                             LoadingWarehouse,
                             ValueInWholeUSDollars,
                             Vin,
                             Year,
                             ProductId
                           }) => {
                  return (
                    <div className="col-xs-12 col-md-6 col-lg-4 mb25">
                      <div className="card for-sell-card">
                        <Link to={`/for-sale-details?id=${Id}`}>
                          {Pictures && Pictures.length ? (
                            <Image image={Pictures.sort((a, b) => {
                              const aParam = new URLSearchParams(a)
                              const bParam = new URLSearchParams(b)
                              if (aParam.get("filename") > bParam.get("filename")) {
                                return 1
                              } else if (aParam.get("filename") < bParam.get("filename")) {
                                return -1
                              }
                              return [0]
                            })[0]} className="card-img-top" alt="card-image"/>
                          ) : (
                            <Image image={"no-photo-for-car.png"} className="card-img-top" alt="card-image"/>
                          )}
                        </Link>
                        <div className="card-block">
                          {ProductId ? (
                            <h5 className="card-title">{ProductId}</h5>
                          ) : (
                            <h5 className="card-title">{Year} {Make} {Model}</h5>
                          )}
                          <div className="card-description">
                            <ul>
                              {EngineType ? (
                                <li className="row">
                                  <div className="col-md-5 name">Engine Type:</div>
                                  <div className="col-md-7">{EngineType || ""}</div>
                                </li>) : null
                              }
                              {EngineLiters ? (
                                <li className="row">
                                  <div className="col-xs-5 name">Liters:</div>
                                  <div className="col-xs-7">{EngineLiters || ""}</div>
                                </li>
                              ) : null}
                              {EngineCyl ? (
                                <li className="row">
                                  <div className="col-xs-5 name">Cylinders:</div>
                                  <div className="col-xs-7">{EngineCyl || ""}</div>
                                </li>
                              ) : null}
                              {BodyStyle ? (
                                <li className="row">
                                  <div className="col-xs-5 name">Body Style:</div>
                                  <div className="col-xs-7">{BodyStyle || ""}</div>
                                </li>
                              ) : null}
                              {Color ? (
                                <li className="row">
                                  <div className="col-xs-5 name">Color:</div>
                                </li>) : null}
                              {PortOfExportId ? (
                                <li className="row">
                                  <div className="col-xs-5 name">Location:</div>
                                  <div
                                    className="col-xs-7">{LoadingWarehouse || PortOfExport || ""}</div>
                                </li>
                              ) : null}
                            </ul>
                          </div>
                          <div className="text-center">
                            <a href={`/for-sale-details?id=${Id}`} className="btn btn-primary">More Details</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          ) : <span><div className="loader"/></span>}
        </div>
      </div>
      {total && total > limit ? (
        <div className="container mb50 text-center">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                {currentPage === 0 ? (
                  <span className="page-link">&lt;</span>
                ) : (
                  <a href={`/for-sale?skip=${(currentPage - 1) * limit}`}>
                    &lt;
                  </a>
                )}
              </li>
              {Array.from({ length: numPages }).map((_, index) => {
                return (
                  <li className={`page-item ${index === currentPage ? "active" : ""}`}>
                    <a href={`/for-sale?skip=${index * limit}`}>
                      {index + 1}
                    </a>
                  </li>
                )
              })}
              <li className="page-item">
                {currentPage === numPages - 1 ? (
                  <span className="page-link">&gt;</span>
                ) : (
                  <a href={`/for-sale?skip=${(currentPage + 1) * limit}`}>
                    &gt;
                  </a>
                )}
              </li>
            </ul>
          </nav>
        </div>
      ) : null
      }
    </div>
  )
}
